export function stringNormalize(str = '') {
    return str.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
}

export function validarCliente(cliente) {
    let errors = [];

    if (!Object.keys(cliente).length) {
        errors.push("Os dados do cliente devem ser preenchidos.");
    } else {
        if (!cliente.cpf) {
            errors.push("O CPF do cliente é obrigatório.");
        }
        if (!cliente.nome) {
            errors.push("O nome do cliente é obrigatório.");
        }
        if (!cliente.telefone) {
            errors.push("O telefone do cliente é obrigatório.");
        }
        /*
        if (!cliente.dataNascimento) {
          errors.push("A data de nascimento do cliente é obrigatória.");
        } 
        */
        if (!cliente.email) {
            errors.push("O e-mail do cliente é obrigatório.");
        }
        if (!cliente.cep) {
            errors.push("O CEP do cliente é obrigatório.");
        }
        if (!cliente.rua) {
            errors.push("A rua do cliente é obrigatória.");
        }
        if (!cliente.numero) {
            errors.push("O número do cliente é obrigatório.");
        }
        if (!cliente.uf) {
            errors.push("O estado (UF) do cliente é obrigatório.");
        }
        if (!cliente.cidade) {
            errors.push("A cidade do cliente é obrigatória.");
        }
        if (!cliente.bairro) {
            errors.push("O bairro do cliente é obrigatório.");
        }
    }
    return errors;
}

export function validarPedido(pedido) {
    let errors = [];
    errors = errors.concat(validarItens(pedido.itens));
    errors = errors.concat(validarCliente(pedido.cliente));
    errors = errors.concat(validarOpcaoPagamento(pedido.opcaoPagamento));
    errors = errors.concat(validarOpcaoEntregaRetirada(pedido.opcaoEntregaRetirada));
    return errors;
}

export function validarItens(itens) {
    let errors = [];

    if (itens.length < 1) {
        errors.push("Selecione ao menos um produto.");
    }

    itens.forEach(item => {
        if (item.quantidade > item.quantidadeEstoque) {
            errors.push(`Quantidade indisponível: ${item.nome}.`);
        }
    });

    return errors;
}

export function validarOpcaoEntregaRetirada(opcao) {
    let errors = [];

    if (Object.keys(opcao).length === 0) {
        errors.push("Escolha alguma opção de entrega ou retirada.");
    }

    if (opcao && !opcao.metodo) {
        errors.push("Selecione entrega ou retirada.");
    }

    if (opcao && !opcao.agendamento) {
        errors.push("Selecione uma opção de agendamento para a entrega ou retirada.");
    }

    return errors;
}

export function validarOpcaoPagamento(opcao) {
    let errors = [];
    if (!opcao) {
        errors.push("Selecione uma opção de pagamento.");
    }
    return errors;
}