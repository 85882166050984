<template>
  <div class="item-produto">
    <div class="row produto">
      <div class="col-xs-12 col-md-3 text-center">
        <img :src="produto.imagemUrl" alt="Imagem do produto" class="produto-imagem"/>
      </div>
      <div class="col-xs-12 col-md-3 center nome-produto font-weight-bold">{{ produto.nome }}</div>
      <div class="col-xs-12 col-md-3 center preco-produto font-weight-bold">
        {{ produto.precoNormal | dinheiro }}
      </div>
      <div class="col-xs-12 col-md-3 text-md-right center">
        <div class="input-group number-spinner">
          <div class="input-group-prepend">
            <button
              class="btn btn-success"
              data-dir="dwn"
              type="button"
              @click="mudaQuantidade"
            >
              <span>&#8722;</span>
            </button>
          </div>
          <input
            type="number"
            class="form-control text-center"
            min="0"
            :max="produto.quantidadeEstoque"
            value="0"
            v-model="produto.quantidade"
          />
          <div class="input-group-append">
            <button
              class="btn btn-success"
              data-dir="up"
              type="button"
              @click="mudaQuantidade"
            >
              <span>&#43;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!estoqueDisponivel">
      <div class="container-fluid text-center">
        <div class="alert alert-warning" role="alert">
          <strong>Atenção:</strong> Essa quantidade pode estar indisponível no
          estoque.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemProduto",
  props: {
    produto: {
      type: Object,
      required: true,
    },
  },
  methods: {
    mudaQuantidade(e) {
      const direcao = e.currentTarget.getAttribute("data-dir");
      if (direcao === "dwn" && this.produto.quantidade > 0) {
        --this.produto.quantidade;
      } else if (direcao === "up") {
        ++this.produto.quantidade;
      }
    },
  },
  computed: {
    estoqueDisponivel() {
      return this.produto.quantidade <= this.produto.quantidadeEstoque;
    },
  },
};
</script>