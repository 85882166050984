import api from "./api";

/**
 * @typedef {ProdutoService}
 */
export default class ProdutoService {

    constructor() {
        this.engineUrl = "/admin/engine/painel/lista-de-compras.php";
    }

    /**
     * @param {Number} categoria
     */
    listarProdutos(categoria) {
        return api().get(this.engineUrl, {
            params: {
                action: "listar-produtos",
                categoria: categoria
            }
        });
    }
}
