<template>
  <div>
    <div class="container-fluid">
      <div class="p-2">
        <lista-produtos
          @atualizaProdutos="buscarItensSelecionados"
          :categoria="categoria"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ListaProdutos from "@/components/produtos/ListaProdutos.vue";

export default {
  name: "Produtos",
  components: {
    ListaProdutos,
  },
  data() {
    return {
      categoria: null,
    };
  },
  methods: {
    ...mapMutations({
      setItensPedido: "setItensPedido",
    }),
    buscarItensSelecionados: function (produtos) {
      const itensSelecionados = this.itensPedido;
      produtos.forEach((produto) => {
        const index = itensSelecionados.findIndex((p) => p.id === produto.id);
        if (index !== -1) {
          itensSelecionados.splice(index, 1);
        }
        if (produto.quantidade > 0) {
          itensSelecionados.push(produto);
        }
      });
      this.setItensPedido(itensSelecionados);
    },
  },
  computed: {
    ...mapGetters({
      itensPedido: "getItens",
    }),
  },
  created() {
    this.categoria = parseInt(this.$route.params.categoria);
  },
};
</script>

<style>
</style>