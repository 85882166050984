<template>
  <div class="">
    <div class="" id="listaProdutos">
      <h2 class="h4 font-weight-bold text-center">Produtos</h2>
      <p class="text-center text-muted small">Selecione os produtos desejados dessa categoria e clique no botão "Continuar" para confirmar a seleção.</p>
    </div>
    <div class="">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-default">Busca</span>
        </div>
        <input
          type="text"
          class="form-control"
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-default"
          v-model="search"
        />
      </div>
      <div v-if="loadingItems" class="text-center">
        <div class="spinner-grow text-warning my-5" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else v-for="produto in filteredList()" :key="produto.id">
        <item-produto :produto="produto" />
      </div>
      <div v-if="produtos.length === 0" class="text-right">
        <div class="text-center">
          <p class="lead">Nenhum produto encontrado</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProdutoService from "../../service/ProdutoService";
import { stringNormalize } from "../../util/functions";
import ItemProduto from "./components/ItemProduto.vue";

export default {
  components: {
    ItemProduto,
  },
  props: {
    categoria: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      produtoService: new ProdutoService(),
      search: "",
      loadingItems: true,
      produtos: [],
    };
  },
  methods: {
    filteredList() {
      this.search = stringNormalize(this.search);
      if (this.search) {
        return this.produtos.filter((item) =>
          stringNormalize(item.nome).includes(this.search)
        );
      } else {
        return this.produtos;
      }
    },

    async getProdutos() {
      try {
        let response = await this.produtoService.listarProdutos(this.categoria);
        this.produtos = response.data;
        if (this.produtos) {
          this.setarQuantidades();
        }
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      } finally {
        this.loadingItems = false;
      }
    },

    setarQuantidades() {
      for (let i = 0; i < this.produtos.length; i++) {
        const idProduto = this.produtos[i].id;
        const item = this.itens.find((item) => item.id === idProduto);
        this.$set(this.produtos[i], "quantidade", item ? item.quantidade : 0);
      }
    },
  },
  watch: {
    produtos: {
      handler() {
        this.$emit("atualizaProdutos", this.produtos);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      itens: "getItens",
    }),
  },
  created() {
    this.getProdutos();
  },
};
</script>
